export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    setTimeout(() => {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  } else {
    window.scrollTo(0, 0);

  }
};

export const onInitialClientRender = () => {
  document.documentElement.style.scrollBehavior = "auto";
};

export const shouldUpdateScroll = () => {
  return false;
}
